import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { MDXLayout as PageLayout } from "../../components/blocks/mdx-layout";
import { SEO } from "../../components/seo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PageLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEO title="About" description="A little bit about me and what I'm passionate about, what I do at work, and what else I do in my free time. Maybe you're also into photography or hiking?" noIndex mdxType="SEO" />

    <h1 {...{
      "id": "about"
    }}>{`About`}</h1>
    <div className="img-left-wrap-text">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "680px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/443299cb1ac4fb757037d0ad2c77fb42/8f2b7/pfp.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "99.609375%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAQDBf/EABgBAQADAQAAAAAAAAAAAAAAAAMAAQIE/9oADAMBAAIQAxAAAAGuayA97NlTlRnQXXAJ/8QAGxAAAgIDAQAAAAAAAAAAAAAAAQIDEgAEEyH/2gAIAQEAAQUCa0UcUwLSeu5HEFVagJ2CbnNZjx//xAAaEQACAgMAAAAAAAAAAAAAAAAAAQIQESEx/9oACAEDAQE/AVsUsD7X/8QAGBEBAAMBAAAAAAAAAAAAAAAAAAERMQL/2gAIAQIBAT8BVbnE6//EABwQAAMAAQUAAAAAAAAAAAAAAAABEQIQEiIxQf/aAAgBAQAGPwLdeJmmpUUuUkO0MWPiWiP/xAAbEAADAQEAAwAAAAAAAAAAAAAAAREhQTFRcf/aAAgBAQABPyFfIVjg3A+V+mxGnSAzBadYvEekaPPPgQmDFHZh/9oADAMBAAIAAwAAABD7zzz/xAAXEQEBAQEAAAAAAAAAAAAAAAABABEx/9oACAEDAQE/EFh2EBdY5f/EABcRAAMBAAAAAAAAAAAAAAAAAAABMRH/2gAIAQIBAT8QayDdtIlj/8QAIRABAAICAQMFAAAAAAAAAAAAAQAhETFBUZHBgaHR8PH/2gAIAQEAAT8QzIWou+viFFijo/UwIDRO3iIMnac6mSvEiWfcRWAAde/mVqP6lrCyqXakhbcGp//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Thomas wearing a dark blue sweater in the Art Gallery of Ontario.",
              "title": "Thomas wearing a dark blue sweater in the Art Gallery of Ontario.",
              "src": "/static/443299cb1ac4fb757037d0ad2c77fb42/8f2b7/pfp.jpg",
              "srcSet": ["/static/443299cb1ac4fb757037d0ad2c77fb42/b95e4/pfp.jpg 256w", "/static/443299cb1ac4fb757037d0ad2c77fb42/1779b/pfp.jpg 512w", "/static/443299cb1ac4fb757037d0ad2c77fb42/8f2b7/pfp.jpg 680w"],
              "sizes": "(max-width: 680px) 100vw, 680px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><em parentName="p">{`Hey, that’s me on the side`}</em>{` 👋 `}<br /><br />{`
I’m passionate about creating great products, building communities around them and working with great people.`}<br /><br />{`
2021 `}<a parentName="p" {...{
          "href": "https://umich.edu"
        }}>{`University of Michigan`}</a>{` computer science alumni & currently @ `}<a parentName="p" {...{
          "href": "https://microsoft.com"
        }}>{`Microsoft`}</a>{`.`}<br /><br />{`
You can contact me at `}<a parentName="p" {...{
          "href": "mailto:thomas@tlai.io"
        }}>{`thomas@tlai.io`}</a>{`. You can also find me on `}<a parentName="p" {...{
          "href": "https://linkedin.com/tplai"
        }}>{`LinkedIn`}</a>{` and `}<a parentName="p" {...{
          "href": "https://github.com/tplai"
        }}>{`GitHub`}</a>{`.`}</p>
    </div>
    <h2 {...{
      "id": "things-to-do-around-here"
    }}>{`Things to do around here`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/projects"
        }}>{`Projects`}</a>{` - some of the projects I’ve worked on.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles"
        }}>{`Articles`}</a>{` - Longer articles on technical work.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/wiki"
        }}>{`Wiki`}</a>{` - miscellaneous writings about keyboards, PCs, and… Chick-fil-A sauces?`}</li>
      <li parentName="ul">{`Maybe some easter eggs here and there 🤷‍♀️.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      